<template>
  <div class="billing-statement-settings-dropdown">
    <v-menu
      bottom
      content-class="menu"
      max-height="90vh"
      offset-y
      v-model="isOpen"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">
          <v-icon class="mr-2">{{$icon('i.BillingStatementSettings')}}</v-icon>
          <span>{{$t('t.BillingStatementSettings')}}</span>
        </v-btn>
      </template>

      <v-card
        v-if="innerSettings"
        class="d-flex flex-column"
      >
        <v-card-actions>
          <v-btn
            fab
            small
            color="success"
            @click.stop="close(true)"
          >
            <v-icon ref="check">{{$icon('i.Checked')}}</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="warning"
            @click.stop="close()"
          >
            <v-icon ref="check">{{$icon('i.Undo')}}</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="background-translucid pl-4 pr-6 pb-4 d-flex flex-column overflow-y-auto">
          <div
            v-if="!invoiceMode"
            class="d-flex flex-row mb-4 align-center"
          >
            <v-checkbox v-model="showAgingBucket" />
            <v-select
              v-if="showAgingBucket"
              class=" mt-4"
              :items="agingBucketSets"
              item-text="name"
              item-value="id"
              v-model="innerSettings.agingBucketSet"
              :disabled="!showAgingBucket"
            />
            <span v-else>{{$t('t.ShowAgingBucket')}}</span>
          </div>
          <div class="d-flex flex-row ">
            <div
              class="include d-flex flex-column mr-8"
              v-if="!invoiceMode"
            >
              <span>{{$t('t.Include')}}</span>
              <v-checkbox
                hide-details
                :label="$t('t.IncludeInvoicesCurrent')"
                v-model="innerSettings.includeInvoicesCurrent"
              />
              <v-checkbox
                hide-details
                class="ml-8"
                :label="$t('t.IncludeOnlyNextDueDate')"
                v-model="innerSettings.includeOnlyNextDueDate"
                :disabled="!innerSettings.includeInvoicesCurrent"
              />
              <v-checkbox
                hide-details
                :label="$t('t.IncludeInvoicesPastDue')"
                v-model="innerSettings.includeInvoicesPastDue"
              />
            </div>
            <div
              class="exclude d-flex flex-column "
              v-if="!invoiceMode"
            >
              <span>{{$t('t.Exclude')}}</span>
              <div class="d-flex flex-column mr-8">
                <v-checkbox
                  hide-details
                  :label="$t('t.ExcludeNegativeInvoices')"
                  v-model="innerSettings.excludeNegativeInvoices"
                />
                <v-checkbox
                  hide-details
                  :label="$t('t.ExcludePromisedInvoices')"
                  v-model="innerSettings.excludePromisedInvoices"
                  :disabled="innerSettings.excludeNotPromisedInvoices"
                />
                <v-checkbox
                  hide-details
                  :label="$t('t.ExcludeNotPromisedInvoices')"
                  v-model="innerSettings.excludeNotPromisedInvoices"
                  :disabled="innerSettings.excludePromisedInvoices"
                />
                <v-checkbox
                  hide-details
                  :label="$t('t.ExcludeDisputedInvoices')"
                  v-model="innerSettings.excludeDisputedInvoices"
                  :disabled="innerSettings.excludeNotDisputedInvoices"
                />
                <v-checkbox
                  hide-details
                  :label="$t('t.ExcludeNotDisputedInvoices')"
                  v-model="innerSettings.excludeNotDisputedInvoices"
                  :disabled="innerSettings.excludeDisputedInvoices"
                />
                <v-checkbox
                  hide-details
                  :label="$t('t.ExcludeAnnouncedInvoices')"
                  v-model="innerSettings.excludeAnnouncedInvoices"
                />
                <v-checkbox
                  v-for="(_, idx) in innerSettings.transactionTypes"
                  :key="idx"
                  hide-details
                  :label="_.displayName"
                  v-model="_.exclude"
                />
              </div>
            </div>
            <div class="others d-flex flex-column ">
              <span>{{$t('t.Others')}}</span>
              <v-checkbox
                hide-details
                :label="$t('t.ShowUnappliedCash')"
                v-model="innerSettings.showUnappliedCash"
              />
              <v-checkbox
                class="ml-8"
                hide-details
                :label="$t('t.ShowAmountTransaction')"
                v-model="innerSettings.showUnappliedCashAmountTransaction"
                :disabled="!settings.showUnappliedCash || settings.hideUnappliedCashAmountAccount"
              />
              <v-checkbox
                class="ml-16"
                hide-details
                :label="$t('t.HideAmountAccount')"
                v-model="innerSettings.hideUnappliedCashAmountAccount"
                :disabled="!settings.showUnappliedCash || !settings.showUnappliedCashAmountTransaction"
              />
              <v-checkbox
                hide-details
                :label="$t('t.ShowWorkInProgress')"
                v-model="innerSettings.showWorkInProgress"
              />
              <v-checkbox
                hide-details
                :label="$t('t.GroupByAccounts')"
                v-model="innerSettings.groupByAccounts"
              />
              <v-checkbox
                hide-details
                :label="$t('t.ShowInvoicesDaysPastDue')"
                v-model="innerSettings.showInvoicesDaysPastDue"
              />
              <v-text-field
                type="number"
                class="ml-16"
                :rules="rules"
                :label="$t('t.Days')"
                v-model="innerSettings.showInvoicesDaysPastDueNumber"
              />
              <span class="mt-5">{{$t('t.GroupTransactionTypes')}}</span>
              <v-checkbox
                v-for="(_, idx) in innerSettings.transactionTypes"
                :key="idx"
                hide-details
                :label="_.displayName"
                v-model="_.groupBy"
                :disabled="_.exclude"
              />
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
  components: {
  },
  async mounted () {
    const r = await this.$http().get('/agingBucket')
    this.agingBucketSets = r.data.items

    // keep original agingBucketSet
    this.originalAgingBucketSetId = this.settings.agingBucketSet
  },
  computed: {
    rules () {
      return [
        v => Number.isInteger(Number(v || NaN)),
        v => (v >= -999 && v <= 999) || this.$t('t.InvalidValueRange', { max: 999, min: -999 })
      ]
    }
  },
  data () {
    return {
      innerSettings: null,
      originalAgingBucketSetId: null,
      isOpen: false,
      agingBucketSets: [],
      showAgingBucket: true
    }
  },
  methods: {
    close (apply) {
      if (apply && this.onApply) {
        if (this.invoiceMode) {
          this.innerSettings.includeInvoicesCurrent = false
          this.innerSettings.includeOnlyNextDueDate = false
          this.innerSettings.includeInvoicesPastDue = false
          this.innerSettings.excludeNegativeInvoices = false
          this.innerSettings.excludePromisedInvoices = false
          this.innerSettings.excludeNotPromisedInvoices = false
          this.innerSettings.excludeDisputedInvoices = false
          this.innerSettings.excludeNotDisputedInvoices = false
          this.innerSettings.excludeAnnouncedInvoices = false
          this.innerSettings.transactionTypes.forEach(function (t) {
            t.exclude = false
          })
          this.innerSettings.agingBucketSet = null
        }
        this.onApply(this.innerSettings)
      }

      this.isOpen = false
    }
  },
  watch: {
    isOpen (v) {
      if (v) {
        this.innerSettings = this.lodash.cloneDeep(this.settings)
        this.showAgingBucket = this.innerSettings.agingBucketSet || false
      }
    },
    showAgingBucket (v) {
      if (v) {
        this.innerSettings.agingBucketSet = this.originalAgingBucketSetId
      } else {
        this.innerSettings.agingBucketSet = null
      }
    }
  },
  props: {
    settings: Object,
    onApply: Function,
    invoiceMode: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.menu
  display flex
</style>
